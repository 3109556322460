import React from 'react'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div className="is-relative">
      <Navbar />
      <div className="p-5b pt-5b p-4-mobile has-background-light" style={{ paddingBottom: '25rem', }}>
        <div className="container ml-0 mr-auto pt-5b">
          <div className="content">
            <h1>Not Found</h1>
            <p className="mb-3">That page does not exist.</p>
            <p className="mb-3"><Link to={`/`} className="">Return Home</Link></p>
            <p><Link to={`/contact`} className="">Contact Us</Link></p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
